export const PopularLists = [
  {
    id: 1,
    name: 'Heart & Cardiovascular Health',
    desc: "Optimal coenzyme absorption: patented KANEKA's reduced coenzyme ingredients, with patented BIOPERINE blend, blended with a high-content coenzyme, fish oil.",
    picture: require('@/assets/images/pop1.png'),
    link: '',
    color: '#15BD9E',
    catagory: '',
    relatedProducts: ['Ubiquinol100','Active','CoQ10'],
  }, {
    id: 2,
    name: 'Essential Fatty Acid',
    desc: "Premium Omega-3 fish oil product sourced from wild Alaskan fish, guaranteeing high-quality ingredients through a sustainable sourcing process.",
    picture: require('@/assets/images/pop2.png'),
    link: '',
    color: '#FF9873',
    relatedProducts: ['Omega3'],
  }, {
    id: 3,
    name: 'Digestive Health',
    desc: "Potent gut health support with our super active 60 Billion CFU with 7 potent strains of probiotic— Safe, effective, and perfect for daily use.",
    picture: require('@/assets/images/pop3.png'),
    link: '',
    color: '#8B9BFF',
    relatedProducts: ['Probiotic'],
  }, {
    id: 4,
    name: 'Vision Health',
    desc: "Our 6-in-1 Vision Protection Complex with Lutein, Zeaxanthin, Astaxanthin, Vitamin C, Vitamin E, and Crocin, provides efficient and fast protection for your eyes.",
    picture: require('@/assets/images/pop4.png'),
    link: '',
    color: '#CC0099',
    relatedProducts: ['Vision', 'Lutein'],
  }, {
    id: 5,
    name: 'Essential Vitamins',
    desc: "Potent Vitamin D3 and K2 in a highly-absorbable ratio. Give your bones strong, healthy support. Essential for your daily routine!",
    picture: require('@/assets/images/pop5.png'),
    link: '',
    color: '#66CC00',
    relatedProducts: ['Vitamin'],
  }, {
    id: 6,
    name: 'Joint Health',
    desc: "Unique 6-In-One Glucosamine Supplement. This potent blend of Glucosamine, MSM, Chondroitin, Mastic, Curcumin, and Quercetin ensures optimal joint health and mobility.",
    picture: require('@/assets/images/pop6.png'),
    link: '',
    color: '#D0AA00',
    relatedProducts: ['Glucosamine500','Glucosamine750'],
  }, {
    id: 7,
    name: 'Herbal Supplements',
    desc: "Extra Strength Ashwagandha supplements effectively regulates cortisol levels to promote stress reduction and a positive mood. It also effectively reduces the production of free radical and oxidative damage, potentially supporting brain health and encouraging memory.",
    picture: require('@/assets/images/pop7.png'),
    link: '',
    color: '#D0AA00',
    relatedProducts: ['Ashwagandha'],
  }, {
    id: 8,
    name: "Women's Essentials",
    desc: "Our evening primrose oil product, with a potent 1500mg per serving, is carefully crafted to support women's health and boost immune system, particularly in alleviating premenstrual syndrome (PMS) discomfort. It also help moisturize the skin with the high levels of GLA (gamma-linolenic acid), an omega-6 essential fatty.",
    picture: require('@/assets/images/pop8.png'),
    link: '',
    color: '#D0AA00',
    relatedProducts: ['Evening'],
  }
]

export const SellerLists = [
  {
    id: 1,
    unique: 'Ashwagandha',
    name: 'Ashwagandha',
    fullName: 'Ashwagandha \nCapsule',
    desc: "Each serving contains 300 mg of Ashwagandha Extract (10:1), equivalent to 3000 mg of Ashwagandha Herb.",
    picture: require('@/assets/images/products/product1.png'),
    logo: require('@/assets/images/products/product1-logo.png'),
    link: '',
    productSwipers: [require('@/assets/images/products/product1-1.png'), require('@/assets/images/products/product1-2.png'), require('@/assets/images/products/product1-3.png'), require('@/assets/images/products/product1-4.png')],
    detailsSwipers: [
      require('@/assets/images/products/details/Ashwagandha/ASHWAGANDHA-1.jpg'),
      require('@/assets/images/products/details/Ashwagandha/ASHWAGANDHA-2.jpg'),
      require('@/assets/images/products/details/Ashwagandha/ASHWAGANDHA-3.jpg'),
      require('@/assets/images/products/details/Ashwagandha/ASHWAGANDHA-4.jpg'),
      require('@/assets/images/products/details/Ashwagandha/ASHWAGANDHA-5.jpg'),
      require('@/assets/images/products/details/Ashwagandha/ASHWAGANDHA-6.jpg'),
    ],
    productDesc: [
      {name:'Flavor', value:'Unflavored'},
      {name:'Unit Count', value:'120 Count'},
      {name:'Item Form', value:'Capsule'},
      {name:'Special Ingredients', value:'Ashwagandha (10:1 Extract) 300mg (Equivalent to 3000mg of Ashwagandha Herb) Per 2 Capsules'},
      {name:'Diet Type', value:'Gluten Free, GMO Free, Soy Free, Vegetarian'},
      {name:'Age Range', value:'Adult'},
    ],
  }, {
    id: 2,
    unique: 'Probiotic',
    name: 'Probiotic',
    fullName: 'Probiotic \nCapsule',
    desc: "Advanced formula provides 60 billion CFU per capsule. Contains 7 Strains of Friendly Bacteria to Support Healthy Digestive System and A Healthy Intestinal Flora, Healthy Digestive System.",
    picture: require('@/assets/images/products/product2.png'),
    logo: require('@/assets/images/products/product2-logo.png'),
    link: '',
    productSwipers: [require('@/assets/images/products/product2-1.png'), require('@/assets/images/products/product2-2.png'), require('@/assets/images/products/product2-3.png'), require('@/assets/images/products/product2-4.png')],
    detailsSwipers: [
      require('@/assets/images/products/details/Probiotic/PROBIOTIC-1.jpg'),
      require('@/assets/images/products/details/Probiotic/PROBIOTIC-2.jpg'),
      require('@/assets/images/products/details/Probiotic/PROBIOTIC-3.jpg'),
      require('@/assets/images/products/details/Probiotic/PROBIOTIC-4.jpg'),
      require('@/assets/images/products/details/Probiotic/PROBIOTIC-5.jpg'),
      require('@/assets/images/products/details/Probiotic/PROBIOTIC-6.jpg'),
    ],
    productDesc: [
      {name:'Flavor', value:'Unflavored'},
      {name:'Unit Count', value:'60 Count'},
      {name:'Item Form', value:'Capsule'},
      {name:'Special Ingredients', value:'60 Billion CFU*** / Capsule, Lacticaseibacillus Casei, Lactobacillus Acidophilus, Lactobacillus Plantarum, Bifida Ferment Lysate, Bifidobacterium, Lactobacillus Longum, and Lactobacillus Delbrueckii Subsp. Bulgaricus.'},
      {name:'Product Benefits', value:'Contains 7 Strains of Friendly Bacteria to Support Healthy Digestive System and A Healthy Intestinal Flora, Healthy Digestive System'},
      {name:'Diet Type', value:'Gluten Free, GMO Free, Soy Free, Vegetarian'},
      {name:'Age Range', value:'Adult'},
    ],
  }, {
    id: 3,
    unique: 'Vitamin',
    name: 'Vitamin D3 + K2',
    fullName: 'Vitamin D3+K2 \nSoftgel',
    desc: "Our daily softgel contains 5000 IU (125 mcg) of vitamin D3 and 100 mcg of vitamin K2, to maintain optimal levels of both vitamins for bone, joint, and muscle health.",
    picture: require('@/assets/images/products/product3.png'),
    logo: require('@/assets/images/products/product3-logo.png'),
    link: '',
    productSwipers: [require('@/assets/images/products/product3-1.png'), require('@/assets/images/products/product3-2.png'), require('@/assets/images/products/product3-3.png'), require('@/assets/images/products/product3-4.png')],
    detailsSwipers: [
      require('@/assets/images/products/details/Vitamin/Vitamin-1.jpg'),
      require('@/assets/images/products/details/Vitamin/Vitamin-2.jpg'),
      require('@/assets/images/products/details/Vitamin/Vitamin-3.jpg'),
      require('@/assets/images/products/details/Vitamin/Vitamin-4.jpg'),
      require('@/assets/images/products/details/Vitamin/Vitamin-5.jpg'),
      require('@/assets/images/products/details/Vitamin/Vitamin-6.jpg'),
    ],
    productDesc: [
      {name:'Flavor', value:'Unflavored'},
      {name:'Unit Count', value:'90 Count'},
      {name:'Item Form', value:'Softgel'},
      {name:'Special Ingredients', value:'Vitamin D3 5000 IU (125mcg), Vitamin K2 100mcg Per Softgel'},
      {name:'Product Benefits', value:'Maintenance General Bone, Joint, and Muscle Health, Supports Immune Health, Supports Overall Cardiovascular and Artery Health'},
      {name:'Diet Type', value:'Gluten Free, GMO Free, Soy Free'},
      {name:'Age Range', value:'Adult'},
    ],
  }, {
    id: 4,
    unique: 'Vision',
    name: 'Vision Protection Complex',
    fullName: 'Vision Protection \nComplex Softgel',
    desc: "Special 6-IN-ONE Formula contains 6 essential nutrients of Lutein, Zeaxanthin, Astaxanthin, Vitamin C, Vitamin E, and Crocin for optimal vision and macula health.",
    picture: require('@/assets/images/products/product4.png'),
    logo: require('@/assets/images/products/product4-logo.png'),
    link: '',
    productSwipers: [require('@/assets/images/products/product4-1.png'), require('@/assets/images/products/product4-2.png'), require('@/assets/images/products/product4-3.png'), require('@/assets/images/products/product4-4.png')],
    detailsSwipers: [
      require('@/assets/images/products/details/Vision/VISION-1.jpg'),
      require('@/assets/images/products/details/Vision/VISION-2.jpg'),
      require('@/assets/images/products/details/Vision/VISION-3.jpg'),
      require('@/assets/images/products/details/Vision/VISION-4.jpg'),
      require('@/assets/images/products/details/Vision/VISION-5.jpg'),
      require('@/assets/images/products/details/Vision/VISION-6.jpg'),
    ],
    productDesc: [
      {name:'Flavor', value:'Unflavored'},
      {name:'Unit Count', value:'120 Count'},
      {name:'Item Form', value:'Softgel'},
      {name:'Special Ingredients', value:'Lutein 20mg, Zeaxanthin 2mg, Astaxanthin 6mg, Vitamin C 250mg, Vitamin E 90mg, and Crocin 20mg Per Softgel.'},
      {name:'Product Benefits', value:'Supports Vision and Macula Health.'},
      {name:'Diet Type', value:'Gluten Free, GMO Free, Soy Free'},
      {name:'Age Range', value:'Adult'},
    ],
  }, {
    id: 5,
    unique: 'Lutein',
    name: 'Lutein 40',
    fullName: 'Lutein 40mg \nSoftgel',
    desc: "High content of 40 mg Lutein and 2 mg of Zeaxanthin is formulated to enhance eye health and vision clarity.",
    picture: require('@/assets/images/products/product5.png'),
    logo: require('@/assets/images/products/product5-logo.png'),
    link: '',
    productSwipers: [require('@/assets/images/products/product5-1.png'), require('@/assets/images/products/product5-2.png'), require('@/assets/images/products/product5-3.png'), require('@/assets/images/products/product5-4.png')],
    detailsSwipers: [
      require('@/assets/images/products/details/Lutein/LUTEIN-1.jpg'),
      require('@/assets/images/products/details/Lutein/LUTEIN-2.jpg'),
      require('@/assets/images/products/details/Lutein/LUTEIN-3.jpg'),
      require('@/assets/images/products/details/Lutein/LUTEIN-4.jpg'),
      require('@/assets/images/products/details/Lutein/LUTEIN-5.jpg'),
      require('@/assets/images/products/details/Lutein/LUTEIN-6.jpg'),
    ],
    productDesc: [
      {name:'Flavor', value:'Unflavored'},
      {name:'Unit Count', value:'60 Count'},
      {name:'Item Form', value:'Softgel'},
      {name:'Special Ingredients', value:'Lutein 40mg, Zeaxanthin 2mg Per Softgel'},
      {name:'Product Benefits', value:'Supports Vision and Macula Health'},
      {name:'Diet Type', value:'Gluten Free, GMO Free, Soy Free'},
      {name:'Age Range', value:'Adult'},
    ],
  }, {
    id: 6,
    unique: 'Ubiquinol100',
    name: 'Ubiquinol 100mg',
    fullName: 'Ubiquinol 100mg \nSoftgel',
    desc: "Highly absorbent active Ubiquinol 100mg maximized with blends of 5mg patented BioPerine to enhance nutrient absorption.",
    picture: require('@/assets/images/products/product6.png'),
    logo: require('@/assets/images/products/product6-logo.png'),
    link: '',
    productSwipers: [require('@/assets/images/products/product6-1.png'), require('@/assets/images/products/product6-2.png'), require('@/assets/images/products/product6-3.png'), require('@/assets/images/products/product6-4.png')],
    detailsSwipers: [
      require('@/assets/images/products/details/Ubiquinol100/Ubiquinol100-1.jpg'),
      require('@/assets/images/products/details/Ubiquinol100/Ubiquinol100-2.jpg'),
      require('@/assets/images/products/details/Ubiquinol100/Ubiquinol100-3.jpg'),
      require('@/assets/images/products/details/Ubiquinol100/Ubiquinol100-4.jpg'),
      require('@/assets/images/products/details/Ubiquinol100/Ubiquinol100-5.jpg'),
      require('@/assets/images/products/details/Ubiquinol100/Ubiquinol100-6.jpg'),
    ],
    productDesc: [
      {name:'Flavor', value:'Unflavored'},
      {name:'Unit Count', value:'60 Count'},
      {name:'Item Form', value:'Softgel'},
      {name:'Special Ingredients', value:'Ubiquinol CoQ10 100mg and BioPerine® 5mg Per Softgel'},
      {name:'Product Benefits', value:'Ubiquinol (Active Form of Coenzyme Q10,) A potent Antioxidant Nutrient, Maintenance General Heart and Vascular Health, Boots Body Energy and Alleviate Fatigue'},
      {name:'Diet Type', value:'Gluten Free, GMO Free, Soy Free'},
      {name:'Age Range', value:'Adult'},
    ],
  },{
    id: 7,
    unique: 'Active',
    name: 'Active Coenzyme Q10',
    fullName: 'Active Coenzyme \nQ10 Capsule',
    desc: "A unique formula for complete 360-degree care: skin, hair and nails all in one product!",
    picture: require('@/assets/images/products/product7.png'),
    logo: require('@/assets/images/products/product7-logo.png'),
    link: '',
    productSwipers: [require('@/assets/images/products/product7-1.png'), require('@/assets/images/products/product7-2.png'), require('@/assets/images/products/product7-3.png'), require('@/assets/images/products/product7-4.png')],
    detailsSwipers: [
      require('@/assets/images/products/details/Active/Active-1.jpg'),
      require('@/assets/images/products/details/Active/Active-2.jpg'),
      require('@/assets/images/products/details/Active/Active-3.jpg'),
      require('@/assets/images/products/details/Active/Active-4.jpg'),
      require('@/assets/images/products/details/Active/Active-5.jpg'),
      require('@/assets/images/products/details/Active/Active-6.jpg'),
    ],
    productDesc: [
      {name:'Flavor', value:'Unflavored'},
      {name:'Unit Count', value:'60 Count'},
      {name:'Item Form', value:'Capsule'},
      {name:'Special Ingredients', value:'Ubiquinol CoQ10 100mg'},
      {name:'Product Benefits', value:'Ubiquinol (Active Form of Coenzyme Q10,) A potent Antioxidant Nutrient, Maintenance General Heart and Vascular Health, Boots Body Energy and Alleviate Fatigue'},
      {name:'Diet Type', value:'Gluten Free, GMO Free, Soy Free'},
      {name:'Age Range', value:'Adult'},
    ],
  },{
    id: 8,
    unique: 'Omega3',
    name: 'Omega-3 Fish Oil',
    fullName: 'Omega-3 Fish \nOil Softgel',
    desc: "Premium Omega-3 fish oil product sourced from wild Alaskan fish. Each serving size contains 1000 mg of omega-3 fatty acids, consisting of 600 mg of EPA and 400 mg of DHA.",
    picture: require('@/assets/images/products/product8.png'),
    logo: require('@/assets/images/products/product8-logo.png'),
    link: '',
    productSwipers: [require('@/assets/images/products/product8-1.png'), require('@/assets/images/products/product8-2.png'), require('@/assets/images/products/product8-3.png'), require('@/assets/images/products/product8-4.png')],
    detailsSwipers: [
      require('@/assets/images/products/details/Omega3/Omega3-1.jpg'),
      require('@/assets/images/products/details/Omega3/Omega3-2.jpg'),
      require('@/assets/images/products/details/Omega3/Omega3-3.jpg'),
      require('@/assets/images/products/details/Omega3/Omega3-4.jpg'),
      require('@/assets/images/products/details/Omega3/Omega3-5.jpg'),
      require('@/assets/images/products/details/Omega3/Omega3-6.jpg'),
    ],
    productDesc: [
      {name:'Flavor', value:'Unflavored'},
      {name:'Unit Count', value:'120 Count'},
      {name:'Item Form', value:'Softgel'},
      {name:'Special Ingredients', value:'Omega-3 Fish Oil 1000mg, with 600mg EPA and 400mg DHA Per Softgel.'},
      {name:'Product Benefits', value:'Help With Joint Health, Supports Cardiovascular Health, Brain, and Heart Health.'},
      {name:'Diet Type', value:'Gluten Free, GMO Free'},
      {name:'Age Range', value:'Adult'},
    ],
  },{
    id: 9,
    unique: 'Evening',
    name: 'Evening Primrose Oil',
    fullName: "Evening Primrose \nOil Softgel",
    desc: "Double Strength Formula contains 1500 mg of Evening Primrose Oil with 240 mg of GLA (Gamma-Linolenic Acid.) Support Women's Health, Skin Health, Boots Immune System.",
    picture: require('@/assets/images/products/product9.png'),
    logo: require('@/assets/images/products/product9-logo.png'),
    link: '',
    productSwipers: [require('@/assets/images/products/product9-1.png'), require('@/assets/images/products/product9-2.png'), require('@/assets/images/products/product9-3.png'), require('@/assets/images/products/product9-4.png')],
    detailsSwipers: [
      require('@/assets/images/products/details/Evening/Evening-1.jpg'),
      require('@/assets/images/products/details/Evening/Evening-2.jpg'),
      require('@/assets/images/products/details/Evening/Evening-3.jpg'),
      require('@/assets/images/products/details/Evening/Evening-4.jpg'),
      require('@/assets/images/products/details/Evening/Evening-5.jpg'),
      require('@/assets/images/products/details/Evening/Evening-6.jpg'),
    ],
    productDesc: [
      {name:'Flavor', value:'Unflavored'},
      {name:'Unit Count', value:'120 Count'},
      {name:'Item Form', value:'Softgel'},
      {name:'Special Ingredients', value:'Evening Primrose Oil 1500mg with Gamma-Linolenic Acid 240mg Per Serving'},
      {name:'Product Benefits', value:"Support Women's Health, Plays An Important Role in Skin Health, Boots Immune System"},
      {name:'Diet Type', value:'Gluten Free, GMO Free, Soy Free'},
      {name:'Age Range', value:'Adult'},
    ],
  },{
    id: 10,
    unique: 'Glucosamine500',
    name: 'Glucosamine 500mg',
    fullName: 'Glucosamine 500mg \nCapsule',
    desc: "3-In-One Capsules contains Glucosamine 500 mg, Chondroitin 400 mg, and MSM 100mg with a ratio 5:4:1 has scientific proof to help support joint mobility, structure, comfort, strength, and flexibility.",
    picture: require('@/assets/images/products/product10.png'),
    logo: require('@/assets/images/products/product10-logo.png'),
    link: '',
    productSwipers: [require('@/assets/images/products/product10-1.png'), require('@/assets/images/products/product10-2.png'), require('@/assets/images/products/product10-3.png'), require('@/assets/images/products/product10-4.png')],
    detailsSwipers: [
      require('@/assets/images/products/details/Glucosamine500/Glucosamine500-1.jpg'),
      require('@/assets/images/products/details/Glucosamine500/Glucosamine500-2.jpg'),
      require('@/assets/images/products/details/Glucosamine500/Glucosamine500-3.jpg'),
      require('@/assets/images/products/details/Glucosamine500/Glucosamine500-4.jpg'),
      require('@/assets/images/products/details/Glucosamine500/Glucosamine500-5.jpg'),
      require('@/assets/images/products/details/Glucosamine500/Glucosamine500-6.jpg'),
    ],
    productDesc: [
      {name:'Flavor', value:'Unflavored'},
      {name:'Unit Count', value:'120 Count'},
      {name:'Item Form', value:'Capsule'},
      {name:'Special Ingredients', value:'Glucosamine 500mg, Chondroitin 400mg, MSM 100mg'},
      {name:'Product Benefits', value:'Supports Joint and Cartilage Health, Joint Flexibility & Mobility, Joint Comfort'},
      {name:'Diet Type', value:'Gluten Free, GMO Free, Soy Free'},
      {name:'Age Range', value:'Adult'},
    ],
  },{
    id: 11,
    unique: 'Glucosamine750',
    name: 'Glucosamine 750mg',
    fullName: 'Glucosamine 750mg \nTablet',
    desc: "Our Advanced 6-in-1 Formula combines Glucosamine, Chondroitin, MSM, Mastic, Quercetin, and Curcumin for powerful joint and bone health support",
    picture: require('@/assets/images/products/product11.png'),
    logo: require('@/assets/images/products/product11-logo.png'),
    link: '',
    productSwipers: [require('@/assets/images/products/product11-1.png'), require('@/assets/images/products/product11-2.png'), require('@/assets/images/products/product11-3.png'), require('@/assets/images/products/product11-4.png')],
    detailsSwipers: [
      require('@/assets/images/products/details/Glucosamine750/Glucosamine750-1.jpg'),
      require('@/assets/images/products/details/Glucosamine750/Glucosamine750-2.jpg'),
      require('@/assets/images/products/details/Glucosamine750/Glucosamine750-3.jpg'),
      require('@/assets/images/products/details/Glucosamine750/Glucosamine750-4.jpg'),
      require('@/assets/images/products/details/Glucosamine750/Glucosamine750-5.jpg'),
      require('@/assets/images/products/details/Glucosamine750/Glucosamine750-6.jpg'),
    ],
    productDesc: [
      {name:'Flavor', value:'Unflavored'},
      {name:'Unit Count', value:'180 Count'},
      {name:'Item Form', value:'Tablet'},
      {name:'Special Ingredients', value:'Glucosamine 750mg, Chondroitin 75mg, MSM 12.5mg, Mastic 100mg, Quercetin 12.5mg, Curcumin (4:1) 75mg Per Serving.'},
      {name:'Product Benefits', value:'Supports Joint and Cartilage Health, Joint Flexibility & Mobility, Joint Comfort.'},
      {name:'Diet Type', value:'Gluten Free, GMO Free, Soy Free'},
      {name:'Age Range', value:'Adult'},
    ],
  },{
    id: 12,
    unique: 'CoQ10',
    name: 'CoQ10 (Ubiquinone) 300mg',
    fullName: 'CoQ10 (Ubiquinone) \n300mg Softgel',
    desc: "CoQ10 and Omega-3 serve as vital supporters of heart and cardiovascular health, acting as powerhouses for cellular energy production, maximized with blends of patented BioPerine to enhance nutrient absorption.",
    picture: require('@/assets/images/products/product12.png'),
    logo: require('@/assets/images/products/product12-logo.png'),
    link: '',
    productSwipers: [require('@/assets/images/products/product12-1.png'), require('@/assets/images/products/product12-2.png'), require('@/assets/images/products/product12-3.png'), require('@/assets/images/products/product12-4.png')],
    detailsSwipers: [
      require('@/assets/images/products/details/CoQ10/CoQ10-1.jpg'),
      require('@/assets/images/products/details/CoQ10/CoQ10-2.jpg'),
      require('@/assets/images/products/details/CoQ10/CoQ10-3.jpg'),
      require('@/assets/images/products/details/CoQ10/CoQ10-4.jpg'),
      require('@/assets/images/products/details/CoQ10/CoQ10-5.jpg'),
      require('@/assets/images/products/details/CoQ10/CoQ10-6.jpg'),
    ],
    productDesc: [
      {name:'Flavor', value:'Unflavored'},
      {name:'Unit Count', value:'120 Count'},
      {name:'Item Form', value:'Softgel'},
      {name:'Special Ingredients', value:'CoQ10 (Ubiquinone) 300mg, Omega-3 100mg, and BioPerine® 5mg Per Softgel'},
      {name:'Product Benefits', value:'A Potent Antioxidant Nutrient, Maintains Overall Brain and Cardiovascular Health, Boots Body Energy, Alleviates Fatigue'},
      {name:'Diet Type', value:'Gluten Free, GMO Free, Soy Free'},
      {name:'Age Range', value:'Adult'},
    ],
  },
]

export const LabLists = [
  {
    id: 1,
    name: 'Manufacturing Facilities',
    desc: "",
    picture: require('@/assets/images/factorys/factory1.png'),
    link: '',
  }, {
    id: 2,
    name: 'Manufacturing Facilities',
    desc: "",
    picture: require('@/assets/images/factorys/factory2.png'),
    link: '',
  }, {
    id: 3,
    name: 'Manufacturing Facilities',
    desc: "",
    picture: require('@/assets/images/factorys/factory3.png'),
    link: '',
  }, {
    id: 4,
    name: 'Manufacturing Facilities',
    desc: "",
    picture: require('@/assets/images/factorys/factory4.jpg'),
    link: '',
  }, {
    id: 5,
    name: 'Manufacturing Facilities',
    desc: "",
    picture: require('@/assets/images/factorys/factory5.jpg'),
    link: '',
  }
]